import styled from 'styled-components'

import MEDIA from 'constants/media'


import AETNA from 'assets/images/carrier/Aetna.webp'
import AMERICANEQUITY from 'assets/images/carrier/AmericanEquity.webp'
import AMERICANRELIABLE from 'assets/images/carrier/AmericanReliable.webp'
import AMTRUST from 'assets/images/carrier/Amtrust.webp'
import ASI from 'assets/images/carrier/ASI.webp'
import ASSURITY from 'assets/images/carrier/Assurity.webp'
import ATHENE from 'assets/images/carrier/Athene.webp'
import BLUECROSS from 'assets/images/carrier/BlueCross.webp'
import CIGNA from 'assets/images/carrier/Cigna.webp'
import CNA from 'assets/images/carrier/CNA.webp'
import EQUITRUST from 'assets/images/carrier/Equitrust.webp'
import FIDELITY from 'assets/images/carrier/Fidelity.webp'
import FOREMOST from 'assets/images/carrier/Foremost.webp'
import GUGGENHEIM from 'assets/images/carrier/Guggenheim.webp'
import HARTFORD from 'assets/images/carrier/Hartford.webp'
import HEALTHNET from 'assets/images/carrier/HealthNet.webp'
import HISCOX from 'assets/images/carrier/Hiscox.webp'
import HUMANA from 'assets/images/carrier/Humana.webp'
import IAC from 'assets/images/carrier/IAC.webp'
import JOHNHANCOCK from 'assets/images/carrier/JohnHancock.webp'
import KAISER from 'assets/images/carrier/Kaiser.webp'
import KEMPER from 'assets/images/carrier/Kemper.webp'
import LIBERTYMUTUAL from 'assets/images/carrier/LibertyMutual.webp'
import LIFEMAP from 'assets/images/carrier/LifeMap.webp'
import LINCOLN from 'assets/images/carrier/Lincoln.webp'
import LUMICO from 'assets/images/carrier/Lumico.webp'
import MAPFRE from 'assets/images/carrier/Mapfre.webp'
import METLIFE from 'assets/images/carrier/Metlife.webp'
import MODA from 'assets/images/carrier/Moda.webp'
import MUTUAL from 'assets/images/carrier/MutualofOmaha.webp'
import NATIONWIDE from 'assets/images/carrier/Nationwide.webp'
import OHIONATIONAL from 'assets/images/carrier/OhioNational.webp'
import PACIFICSOURCE from 'assets/images/carrier/PacificSource.webp'
import PHILADELPHIA from 'assets/images/carrier/Philadelphia.webp'
import PROGRESSIVE from 'assets/images/carrier/Progressive.webp'
import PROTECTIVE from 'assets/images/carrier/Protective.webp'
import PROVIDENCE from 'assets/images/carrier/Providence.webp'
import PRUDENTIAL from 'assets/images/carrier/Prudential.webp'
import REDSHIELD from 'assets/images/carrier/Redshield.webp'
import REGENCE from 'assets/images/carrier/Regence.webp'
import SAFECO from 'assets/images/carrier/Safeco.webp'
import STILLWATER from 'assets/images/carrier/Stillwater.webp'
import SYMETRA from 'assets/images/carrier/Symetra.webp'
import TRANSAMERICA from 'assets/images/carrier/Transamerica.webp'
import TRAVELERS from 'assets/images/carrier/Travelers.webp'
import UNITED from 'assets/images/carrier/UnitedHealthCare.webp'
import USLI from 'assets/images/carrier/USLI.webp'

import {
  Props,
  Theme,
} from './types'

/* eslint-disable-next-line import/prefer-default-export */
export const Logo = styled.div`
  width: 240px;
  height: 99px;
  background-color: #ffffff;
  background-size: 200px 83px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in;
  ${MEDIA.small`
    width: 280px;
    height: 115px;
    background-size: 240px 99px;
  `}

  ${(props: Props): string | undefined => (props.theme === Theme.aetna && `
    background-image: url(${AETNA});
  `) || (props.theme === Theme.americanEquity && `
    background-image: url(${AMERICANEQUITY});
  `) || (props.theme === Theme.americanReliable && `
    background-image: url(${AMERICANRELIABLE});
  `) || (props.theme === Theme.amTrust && `
    background-image: url(${AMTRUST});
  `) || (props.theme === Theme.asi && `
    background-image: url(${ASI});
  `) || (props.theme === Theme.assurity && `
    background-image: url(${ASSURITY});
  `) || (props.theme === Theme.athene && `
    background-image: url(${ATHENE});
  `) || (props.theme === Theme.bluecross && `
    background-image: url(${BLUECROSS});
  `) || (props.theme === Theme.cigna && `
    background-image: url(${CIGNA});
  `) || (props.theme === Theme.cna && `
    background-image: url(${CNA});
  `) || (props.theme === Theme.equitrust && `
    background-image: url(${EQUITRUST});
  `) || (props.theme === Theme.fidelity && `
    background-image: url(${FIDELITY});
  `) || (props.theme === Theme.foremost && `
    background-image: url(${FOREMOST});
  `) || (props.theme === Theme.guggenheim && `
    background-image: url(${GUGGENHEIM});
  `) || (props.theme === Theme.hartford && `
    background-image: url(${HARTFORD});
  `) || (props.theme === Theme.healthnet && `
    background-image: url(${HEALTHNET});
  `) || (props.theme === Theme.hiscox && `
    background-image: url(${HISCOX});
  `) || (props.theme === Theme.humana && `
    background-image: url(${HUMANA});
  `) || (props.theme === Theme.iac && `
    background-image: url(${IAC});
  `) || (props.theme === Theme.johnhancock && `
    background-image: url(${JOHNHANCOCK});
  `) || (props.theme === Theme.kaiser && `
    background-image: url(${KAISER});
  `) || ((props.theme === Theme.kemper || props.theme === Theme.kemperSpecialtyAuto) && `
    background-image: url(${KEMPER});
  `) || (props.theme === Theme.libertyMutual && `
    background-image: url(${LIBERTYMUTUAL});
  `) || (props.theme === Theme.lifemap && `
    background-image: url(${LIFEMAP});
  `) || (props.theme === Theme.lincoln && `
    background-image: url(${LINCOLN});
  `) || (props.theme === Theme.lumico && `
    background-image: url(${LUMICO});
  `) || (props.theme === Theme.mapfre && `
    background-image: url(${MAPFRE});
  `) || (props.theme === Theme.metlife && `
    background-image: url(${METLIFE});
  `) || (props.theme === Theme.moda && `
    background-image: url(${MODA});
  `) || (props.theme === Theme.mutual && `
    background-image: url(${MUTUAL});
  `) || (props.theme === Theme.nationwide && `
    background-image: url(${NATIONWIDE});
  `) || (props.theme === Theme.ohioNational && `
    background-image: url(${OHIONATIONAL});
  `) || (props.theme === Theme.pacificsource && `
    background-image: url(${PACIFICSOURCE});
  `) || (props.theme === Theme.philadelphia && `
    background-image: url(${PHILADELPHIA});
  `) || (props.theme === Theme.progressive && `
    background-image: url(${PROGRESSIVE});
  `) || (props.theme === Theme.protective && `
    background-image: url(${PROTECTIVE});
  `) || (props.theme === Theme.providence && `
    background-image: url(${PROVIDENCE});
  `) || (props.theme === Theme.prudential && `
    background-image: url(${PRUDENTIAL});
  `) || (props.theme === Theme.redshield && `
    background-image: url(${REDSHIELD});
  `) || (props.theme === Theme.regence && `
    background-image: url(${REGENCE});
  `) || (props.theme === Theme.safeco && `
    background-image: url(${SAFECO});
  `) || (props.theme === Theme.stillwater && `
    background-image: url(${STILLWATER});
  `) || (props.theme === Theme.symetra && `
    background-image: url(${SYMETRA});
  `) || (props.theme === Theme.transamerica && `
    background-image: url(${TRANSAMERICA});
  `) || (props.theme === Theme.travelers && `
    background-image: url(${TRAVELERS});
  `) || (props.theme === Theme.united && `
    background-image: url(${UNITED});
  `) || (props.theme === Theme.usli && `
    background-image: url(${USLI});
  `) || undefined};
`
