
import styled from 'styled-components'

import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'
import COLORS from 'constants/colors'


export const Wrapper = styled.div<{img: string, isLoading: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 800px;
  padding-bottom: ${LAYOUT.VERTICALSPACEMOBILE};
  padding-top: ${LAYOUT.VERTICALSPACEMOBILE};
  background-image: url(${(props): string => props.img});
  background-size: cover;
  background-position: center center;
  transition: all 0.5s linear;
  ${(props): string | false => props.isLoading && 'filter: blur(10px);'}
  ${MEDIA.medium`
    padding-bottom: ${LAYOUT.VERTICALSPACE};
  `}
`

export const CarrierGroupContent = styled.div`
  padding: 0 20px;
  text-align: center;
`

export const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto 40px auto;
`

export const LogoWrap = styled.div`
  border: 1px solid ${COLORS.GREYMEDIUM};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  margin: 10px;
  &:hover {
    border: 1px solid ${COLORS.BLUE};
    box-shadow: 0px 0px 5px 0px rgba(20, 61, 141, 0.5);
  }
`
