import React from 'react'
import loadable from '@loadable/component'
import ProgressiveImage from 'react-progressive-image'

import FeaturedBgr from 'assets/images/backgrounds/bgr-featured-carriers.webp'
import FeaturedBgrPlaceholder from 'assets/images/backgrounds/bgr-featured-carriers-placeholder'
import CarrierLogo, { Theme as CarrierLogoTheme } from 'components/blocks/CarrierLogo'
import Link, { Theme as LinkTheme } from 'components/base/Link'

import {
  Wrapper,
  CarrierGroupContent,
  Logos,
  LogoWrap,
} from './styles'

const Title = loadable(() => import('../../base/Title').catch(() => () => null))

const FeaturedCarriers: React.FC = (): React.ReactElement => (
  <ProgressiveImage
    src={FeaturedBgr}
    placeholder={FeaturedBgrPlaceholder}
  >
    {(src: string, loading: boolean): React.ReactElement => (
      <Wrapper img={src} isLoading={loading}>
        <CarrierGroupContent>
          <Title level={2}>Some of Our Leading Carrier Partners</Title>
          <Logos>
            <LogoWrap>
              <CarrierLogo theme={CarrierLogoTheme.travelers} />
            </LogoWrap>
            <LogoWrap>
              <CarrierLogo theme={CarrierLogoTheme.mutual} />
            </LogoWrap>
            <LogoWrap>
              <CarrierLogo theme={CarrierLogoTheme.progressive} />
            </LogoWrap>
            <LogoWrap>
              <CarrierLogo theme={CarrierLogoTheme.united} />
            </LogoWrap>
            <LogoWrap>
              <CarrierLogo theme={CarrierLogoTheme.regence} />
            </LogoWrap>
            <LogoWrap>
              <CarrierLogo theme={CarrierLogoTheme.safeco} />
            </LogoWrap>
          </Logos>
        </CarrierGroupContent>
        <Link theme={LinkTheme.button} to='/carriers/'>
          Our Carriers
        </Link>
      </Wrapper>
    )}
  </ProgressiveImage>
)

export default FeaturedCarriers
