import React from 'react'

import { Logo } from './styles'
import {
  Props,
  Theme,
} from './types'

const CarrierLogo: React.FC<Props> = ({
  theme = Theme.default,
  ...props
}): React.ReactElement => <Logo theme={theme} {...props} />

export * from './types'

export default CarrierLogo
